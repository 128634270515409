import { AnyAction } from "redux";
import { HubspotStore } from "../../interfaces";
import { HubspotTypes } from "../types";

const initialState: HubspotStore = {
    authHubspot: null,
    loading: false,
    success: true,
};

export const hubspot = (
    state = initialState,
    action: { type: string; payload: HubspotStore } | AnyAction,
): HubspotStore => {
    const { loading, success, authHubspot } = action.payload || {};
    switch (action.type) {
        case HubspotTypes.HUBSPOT_CONNECT_LOADING:
            return {
                ...state,
                loading,
            };
        case HubspotTypes.HUBSPOT_CONNECT_SUCCESS:
            return {
                ...state,
                authHubspot,
                loading,
                success,
            };
        case HubspotTypes.HUBSPOT_CONNECT_FAILURE:
            return {
                ...state,

                loading,
                success,
            };
        default:
            return state;
    }
};
